import React, { useState } from "react";
import swal from 'sweetalert'
import resumeFile from "../documents/resume.pdf";

const Resume = ({ classicHeader, darkTheme, defaultAccount, language }) => {
    const [contributionAmount, setContributionAmount] = useState(0);
    const [personalLink, setPersonalLink] = useState(null);

    const getLink = () => {
        if (personalLink !== null) return;
        if (defaultAccount === null) return;
        // swal("Error", "You need to connect wallet to get invite link","error"); 
        let tempLink = window.location.origin + "/inviter=" + defaultAccount
        setPersonalLink(tempLink);
    }

    getLink()

    const handleContribute = (value) => {
        console.log(value)
        if (language === "中") {
            if (value === 0)
                swal("成功", "成功認購 50 USDT", "success");
            if (value === 1)
                swal("成功", "成功認購 100 USDT", "success");
            if (value === 2)
                swal("成功", "成功認購 200 USDT", "success");
            return;
        }
        if (value === 0)
            swal("Success", "Successfully Contribute With 50 USDT", "success");
        if (value === 1)
            swal("Success", "Successfully Contribute With 100 USDT", "success");
        if (value === 2)
            swal("Success", "Successfully Contribute With 200 USDT", "success");
    }

    const handleClaim = () => {
        if (language === "中") {
            if (contributionAmount === 0) {
                swal("錯誤", "您並未進行任何認購", "error")
                return;
            }
            swal("錯誤", "現在還不能領幣", "error")
            return;
        }
        if (contributionAmount === 0) {
            swal("Error", "You haven't make any contribution yet", "error")
            return;
        }
        swal("Error", "Cannot Claim Right Now", "error")
    }
    const educationDetails = [
        {
            yearRange: "50 USDT",
            title: language === "中" ? "小貓咪" : "Pussy",
            place: language === "中" ? "小貓咪" : "Little cats",
            // desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
        },
        {
            yearRange: "100 USDT",
            title: language === "中" ? "貓咪" : "Cat",
            place: language === "中" ? "一般貓咪" : "Normal Cats",
            // desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
        },
        {
            yearRange: "200 USDT",
            title: language === "中" ? "老闆貓咪" : "Boss Cat",
            place: language === "中" ? "貓咪之王" : "The King of Cats",
            // desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
        },
    ];

    return (
        <section
            id="resume"
            className={"section " + (darkTheme ? "bg-dark-1" : "")}
        >
            <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
                {/* Heading */}
                <div className="position-relative d-flex text-center mb-5">
                    <h2
                        className={
                            "text-24  text-uppercase fw-600 w-100 mb-0 " +
                            (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
                        }
                    >
                        {language === "中" ? <>貢獻</> : <>Contribution</>}
                    </h2>
                    <p
                        className={
                            "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                            (darkTheme ? "text-white" : "text-dark")
                        }
                    >
                        {" "}
                        IDO
                        <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
                    </p>
                </div>
                {/* Heading end*/}
                <div className="row gx-5">
                    {/* My Education */}

                    <div className="col-md-6">
                        <h2
                            className={
                                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
                            }
                        >
                            {language === "中" ? <>參與 IDO</> : <>IDO Contribution</>}
                        </h2>
                        {educationDetails.length > 0 &&
                            educationDetails.map((value, index) => (
                                <div
                                    key={index}
                                    className={
                                        "bg-white  rounded p-4 mb-4 " +
                                        (darkTheme ? "bg-dark" : "bg-white border")
                                    }
                                    style={{ display: 'flex', flexDirection: 'row' }}
                                >
                                    <div style={{ flex: 1 }}>
                                        <p className="badge bg-primary text-2 fw-400">
                                            {value.yearRange}
                                        </p>
                                        <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                                            {value.title}
                                        </h3>
                                        <p className={darkTheme ? "text-primary" : "text-danger"}>
                                            {value.place}
                                        </p>
                                    </div>

                                    <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <a
                                            className="btn btn-primary rounded-pill"
                                            style={{ maxWidth: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                            onClick={() => handleContribute(index)}
                                        >
                                            {language === "中" ? <>參與</> : <>Contribute</>}
                                        </a>
                                    </div>
                                </div>
                            ))}
                    </div>
                    <div className="col-md-6">
                        <div>
                            <div>
                                <h2
                                    className={
                                        "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
                                    }
                                >
                                    {language === "中" ? <>我的貢獻</> : <>My Contribution</>}
                                </h2>
                                <div
                                    className={
                                        "bg-white  rounded p-4 mb-4 " +
                                        (darkTheme ? "bg-dark" : "bg-white border")
                                    }
                                    style={{ display: 'flex', flexDirection: 'row' }}
                                >
                                    <div style={{ flex: '1' }}>
                                        <p className="badge bg-primary text-2 fw-400">
                                            {language === "中" ? <>貢獻</> : <>Contribution</>}
                                        </p>
                                        <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                                            {contributionAmount} USDT
                                        </h3>
                                        <p className={darkTheme ? "text-primary" : "text-danger"}>
                                            {language === "中" ? <>聰明的選擇</> : <>Smart Choice</>}
                                        </p>
                                    </div>
                                    <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <a
                                            className="btn btn-primary rounded-pill"
                                            style={{ maxWidth: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                            onClick={handleClaim}
                                        >
                                            {language === "中" ? <>提幣</> : <>Claim</>}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              {language === "中" ? <>參與 IDO</> : <>IDO Contribution</>}
            </h2>
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                  style={{ display: 'flex', flexDirection: 'row' }}
                >
                  <div style={{ flex: 1 }}>
                    <p className="badge bg-primary text-2 fw-400">
                      {value.yearRange}
                    </p>
                    <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                      {value.title}
                    </h3>
                    <p className={darkTheme ? "text-primary" : "text-danger"}>
                      {value.place}
                    </p>
                  </div>

                  <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <a
                      className="btn btn-primary rounded-pill"
                      style={{ maxWidth: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      onClick={() => handleContribute(index)}
                    >
                      {language === "中" ? <>參與</> : <>Contribute</>}
                    </a>
                  </div>
                </div>
              ))}
          </div> */}
                    {/* My Experience */}
                </div>
            </div>
        </section>
    );
};

export default Resume;
